// ข้อมูลจัดการผลการประมูล

import { HttpClient, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject} from "rxjs";
import { delay, map, tap } from "rxjs/operators";
import { environment } from "../../../environments/environment";


@Injectable()
export class ResultBiddingService {
  private url = environment.apiUrl;
  private _loading$ = new BehaviorSubject<boolean>(true);
  constructor(private http: HttpClient) {    
  }
  resultBiddingList(data: any) {
    return this.http.post(this.url + '/result-bidding/list', data).pipe(
      tap(() => this._loading$.next(true)
      ),
      delay(200),
      map((res: any) => {
        this._loading$.next(false)
        return res;
      })
      )
  }
  seqBiddingList(data: any) {
    return this.http.post(this.url + '/seq-bidding/list', data).pipe(
      tap(() => this._loading$.next(true)
      ),
      delay(200),
      map((res: any) => {
        this._loading$.next(false)
        return res;
      })
      )
  }
  seqBiddingListById(data: any) {
    return this.http.post(this.url + '/seq-bidding/ById', data)
  }

  logBiddingList(data: any) {
    return this.http.post(this.url + '/log-bidding/list', data).pipe(
      tap(() => this._loading$.next(true)
      ),
      delay(200),
      map((res: any) => {
        this._loading$.next(false)
        return res;
      })
      )
  }
  
  dowLoadLogExcel(data: any) {
    return this.http.post(this.url + '/log-bidding/export/excel',data, {responseType: 'arraybuffer'})
  }
  // planAuctionByName() {
  //   return this.http.get(this.url + '/plan_auction/name')
  // }


  updateRemark(data) {
    return this.http.post(this.url + '/seq-bidding/update/remark', data)
  }
  
  
  resultBiddingById(data: any) {
    return this.http.post(this.url + '/result-bidding/byId', data)
  }

  resultEndBidding() {
    return this.http.get(this.url + '/plan_auction/end-bidding');
  }
  get loading$() { return this._loading$.asObservable(); }

}
