import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { throwError } from 'rxjs';
import { environment } from '../../../environments/environment';



@Injectable()
export class AuthService {
  private url = environment.apiUrl;
  // private urlMobile = environment.apiMobileUrl;

  constructor(private http: HttpClient,
    private route: Router) { }
  login(login) {
    return this.http.post(this.url + `/auth/loginSeller`, login)
  }

  getToken() {
    let token = JSON.parse(localStorage.getItem('token'))
    return token || '';
  }

  logout() {
    let logout = localStorage.removeItem('token');
    if (logout == null) {
      this.http.post(this.url + `/auth/logoutSeller`, logout);

      this.route.navigate(['/auth/login'])

    }
  }

  // mobileLogin(data) {
  //   console.log(data);
  //   return this.http.post(this.urlMobile + `/users/signUser`, data)
  // }
  // mobileLogout(data) {

  //   return this.http.post(this.urlMobile + `/users/logOut`, data)
  // }

  getU() {
    return this.http.get(this.url + "/career");
  }

  get isLoggedIn(): boolean {
    let authToken = localStorage.getItem('token');
    return (authToken !== null) ? true : false;
  }

}
