import { Pipe, PipeTransform } from '@angular/core';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';

@Pipe({ name: 'config_date' })
export class DatePipe implements PipeTransform {

  transform(input: string): any {
    if (input) {
        let newDate =   format(new Date(input), 'dd/MM/yyyy')
        return newDate;
    } else {
        return 'ไม่ระบุ';
    }
  }
}

@Pipe({ name: 'config_datetime' })
export class DateTimePipe implements PipeTransform {

  transform(input: string): any {
    if (input) {
        let newDate =   format(new Date(input), 'dd/MM/yyyy HH:mm:ss')
        return newDate;
    } else {
        return 'ไม่ระบุ';
    }
  }
}
