import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { map } from "rxjs/operators";
import { AuthService } from ".";
import { environment } from "../../../environments/environment";

@Injectable({ providedIn: "root" })
export class EmployeeService {
  private url = environment.apiUrl;
  role: number;
  current_employee: any = {}
  current_seller: any = {}
  constructor(private http: HttpClient,) {}

  getEmployee() {
    return this.http.get(this.url + "/employee/auth").pipe(
      map((res: any) => {
        this.role = res.role;
        this.current_employee = res.data
        return res;
      })
    );
  }

  getSellerAccount(){
    return this.http.get(this.url + "/sellerAccount/auth").pipe(
      map((res: any) => {
        this.current_seller = res.data
        return res;
      })
    );
  }

  getEmployeeInfoById(id: number){
    return this.http.get(this.url + "/employee/empInfoById/" + id);
  }

  getEmployeeById(id: number) {
    let aEmp :any= this.http.get(this.url + "/employee/info/" + id);
    return aEmp.data;
  }


  getEmployeeList() {
    return this.http.get(this.url + "/employee/list");
  }

}
