import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'phone' })
export class PhonePipe implements PipeTransform {

  transform(input: string): any {
      if (input.length == 10) {
        let start_phone = input.slice(0,3)
        let center_phone = input.slice(3,6)
        let end_phone = input.slice(6,10)
        let  sum_phone = start_phone + ' ' + center_phone + ' ' + end_phone
        return sum_phone;
      } else {
          input = ""
          return input;
      }

  }
}
