import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';

@Injectable({ providedIn: "root" })
export class CareerService {
    private url = environment.apiUrl;

    constructor(private http: HttpClient,
                private route: Router) {}
    CareerList() {
        return this.http.get(this.url + "/career");
    }

    SubCareerList(career_id: number) {
        return this.http.get(this.url + "/careersub/" + career_id);
    }
}