import { LayoutService } from './layout.service';
import { AnalyticsService } from './analytics.service';
import { StateService } from './state.service';
import { UserBiddingService } from './user.bidding.service';
import { PlanBiddingService } from './plan-bidding.service';
import { CreditOnlineService } from './credit-online-bidding.service';
import { CashService } from './cash.service';
import { PermisstionBiddingService } from './permisstion-bidding.service';
import { ResultBiddingService } from './result-bidding.service';
import { CareerService } from './career.service';
import { SellerService } from './seller-finance.service';
import { AuthService } from './auth.service';
import { EmployeeService } from './employee.service';
import { AddressService } from './address.service';
import { UserService } from './user.service';
import { PlanBiddingOnlineService } from './plan-bidding-online.service';
export {
  LayoutService,
  AnalyticsService,
  StateService,
  AuthService,
  EmployeeService,
  AddressService,
  // new Service for called API !!!!!!
  UserBiddingService,
  PlanBiddingService,
  CreditOnlineService,
  CashService,
  PermisstionBiddingService,
  ResultBiddingService,
  CareerService,
  SellerService,
  UserService,
  PlanBiddingOnlineService
};
