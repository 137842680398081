import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { SellerUserInfo, MenuGroupInfo, MenuInfo } from '../interface/user-management';
import { ApiResponse, PagableData } from '../interface/common';
import { map } from 'rxjs/operators';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { Socket } from 'ngx-socket-io';
import { Router } from '@angular/router';
@Injectable({
    providedIn: 'root'
})
export class UserProfileService {
    domain: string;
    currentUserProfile = this.socket.fromEvent<any>('user_profile');
    currentPermissionMenu = this.socket.fromEvent<any>('permission_menu');
    currentPermissionMenuUser = this.socket.fromEvent<any>('permission_menu_user');
    constructor(private http: HttpClient, private socket: Socket, private router: Router) {
        this.domain = environment.apiUrl;
    }
    async logout() {
        let out = await this.logoutSeller().toPromise();
        localStorage.removeItem('token');
        this.router.navigate(['/auth/login']);
    }
    logoutSeller(): Observable<any> {
        const uri = `auth/logoutSeller`;
        const url = `${this.domain}/${uri}`;
        return this.http.post<any>(url, {});
    }
    getUserProfile() {
        const uri = `user-profile`;
        const url = `${this.domain}/${uri}`;
        return this.http.get<ApiResponse<SellerUserInfo>>(url);
    }
    updateUserProfile(seller_acc_id: number, firstname: string, lastname: string) {
        const req = { seller_acc_id: seller_acc_id, firstname: firstname, lastname: lastname };
        const uri = `user-profile`;
        const url = `${this.domain}/${uri}`;
        return this.http.put<ApiResponse<any>>(url, req);
    }

    updatePasswordUserProfile(seller_acc_id: number, password: string, confirm_password: string) {
        const req = { seller_acc_id: seller_acc_id, password: password, confirm_password: confirm_password };
        const uri = `user-profile-change-password`;
        const url = `${this.domain}/${uri}`;
        return this.http.put<ApiResponse<any>>(url, req);
    }

    getUserProfileBySellerAccIdSocket(id: number) {
        this.socket.emit('joinRoom', { room: `user_profile_${id}` });
    }

    getPermissionMenuBySellerAccIdSocket(id: number) {
        this.socket.emit('joinRoom', { room: `permission_menu_${id}` });
    }

    getPermissionMenuUserBySellerAccIdSocket(id: number) {
        this.socket.emit('joinRoom', { room: `permission_menu_user_${id}` });
    }

    destroyUserProfileBySellerAccIdSocket(id: number) {
        this.socket.emit('leaveRoom', { room: `user_profile_${id}` });
    }

    destroyPermissionMenuBySellerAccIdSocket(id: number) {
        this.socket.emit('leaveRoom', { room: `permission_menu_${id}` });
    }

    destroyPermissionMenuUserBySellerAccIdSocket(id: number) {
        this.socket.emit('leaveRoom', { room: `permission_menu_user_${id}` });
    }

}