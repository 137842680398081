// ข้อมูลผู้ใช้งานระบบ

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, of, Subject } from "rxjs";
import { debounceTime, delay, switchMap, tap,map } from "rxjs/operators";
import { environment } from "../../../environments/environment";
import { SortDirection } from "../../@theme/directives/sort.directive";

@Injectable({ providedIn: "root" })
export class UserService {
  private url = environment.apiUrl;
  private _loading$ = new BehaviorSubject<boolean>(true);
  constructor(private http: HttpClient) {

  }
  getUser() {
    return this.http.get(this.url + "/user/code").pipe(
      tap(() => this._loading$.next(true)
      ),
      delay(200),
      map((res: any) => {
        this._loading$.next(false)
        return res;
      })
      )
    }
  get loading$() { return this._loading$.asObservable(); }

}
