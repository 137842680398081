<div class="header-container">
  <div class="logo-container">
    <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
      <nb-icon icon="bars" pack="fa"></nb-icon>
    </a>
    <a class="logo" href="#" (click)="navigateHome()">Omakase Bidding {{seller?seller.seller_finance:''}}</a>
  </div>
</div>

<div class="header-container">
  <nb-actions size="small">
    <nb-action class="user-action">
      <nb-action>{{seller?.seller_acc_code}}&nbsp;&nbsp;<span>{{seller?.firstname}} &nbsp; {{seller?.lastname}}</span>
      </nb-action>
      <nb-icon [nbContextMenu]="userMenu" icon="caret-down" nbContextMenuTag="my-context-menu"></nb-icon>
    </nb-action>
  </nb-actions>
</div>
