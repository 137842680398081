import {
    HttpEvent,
    HttpHandler,
    HttpRequest,
    HttpErrorResponse,
    HttpInterceptor
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NbGlobalPhysicalPosition, NbToastrService } from '@nebular/theme';
import { Observable, throwError } from 'rxjs';
import { catchError} from 'rxjs/operators';
import { AuthService } from '../@core/utils';

@Injectable()
export class ErrorIntercept implements HttpInterceptor {
    constructor(private authService: AuthService,
                private toastrservice: NbToastrService) {}
    intercept(
        request: HttpRequest<any>,
        next: HttpHandler,
    ): Observable<HttpEvent<any>> {
        return next.handle(request)
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    let errorMessage = '';
                    if (error.error instanceof ErrorEvent) {
                        // client-side error
                        errorMessage = `Error: ${error.error.message}`;
                    } else {
                        // server-side error
                        errorMessage = `Error Status: ${error.status}\nMessage: ${error.message}`;
                        if (error.status == 401) {
                            // this.toastrservice.show(
                            //     `Message: ${error.error.msg}`, `Error: ${error.status}`, 
                            //     {status: 'danger',
                            //     position: NbGlobalPhysicalPosition.TOP_RIGHT,
                            //     duration: 3000
                            //   });
                            this.authService.logout()
                        } 
                        // else if (error.status == 500) {
                        //     this.toastrservice.show(
                        //         `Message: ${error.message}`, `Error: ${error.status}`, 
                        //         {status: 'danger',
                        //         position: NbGlobalPhysicalPosition.TOP_RIGHT,
                        //         duration: 3000
                        //       });
                        // }
                    }
                    return throwError(error);
                })
            )
    }
}