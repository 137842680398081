// ข้อมูลผู้ใช้งานระบบ

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, of, Subject } from "rxjs";
import { debounceTime, delay, map, switchMap, tap } from "rxjs/operators";
import { environment } from "../../../environments/environment";

@Injectable()
export class PermisstionBiddingService {
  private url = environment.apiUrl;
  private _loading$ = new BehaviorSubject<boolean>(true);

  constructor(private http: HttpClient) {    
  }
  bidderList(data) {
    return this.http.post(this.url + '/bidder/list', data).pipe(
      tap(() => this._loading$.next(true)
      ),
      delay(200),
      map((res: any) => {
        this._loading$.next(false)
        return res;
      })
      )
  }
  getBidderById(id: number) {
    return this.http.get(this.url + `/bidder/${id}`)
  }

  updateBidder(data: any, id: number) {
    return this.http.put(this.url + `/bidder/${id}`, data)
    .pipe(
      tap(() => this._loading$.next(true)),
      delay(200),
      map((res) => {
        this._loading$.next(false);
        return res;
      })
    )
  }

  addBidderExp(data) {
    return this.http.post(this.url + '/bidder', data)
  }
  planAuctionByName() {
    return this.http.get(this.url + '/plan_auction/name')
  }
  bidderExp(data) {
    return this.http.post(this.url + '/bidder/list/exp', data);
  }

  planAuction() {
    return this.http.get(this.url + '/plan_auction/next-round');
  }

  get loading$() { return this._loading$.asObservable(); }


}
