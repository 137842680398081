import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
@Injectable({ providedIn: "root" })
export class AddressService {
    private url = environment.apiUrl;

    constructor(private http: HttpClient,
                private route: Router) {}
    subDistrict(amp_id) {
        return this.http.get(this.url + `/subdistrict/${amp_id}`)
    }

    District(prov_id) {
        return this.http.get(this.url + `/district/${prov_id}`)
    }

    Province() {
        return this.http.get(this.url + `/province`)
    }
}