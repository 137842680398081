import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject} from "rxjs";
import {tap, map, delay } from "rxjs/operators";
import { environment } from "../../../environments/environment";


@Injectable({ providedIn: "root" })
export class CreditOnlineService {
  private url = environment.apiUrl;
  private _loading$ = new BehaviorSubject<boolean>(true);

  constructor(private http: HttpClient) {
    this._loading$.next(false)
  }

  getCreditOnline(data) {
    return this.http.post(this.url + "/credit-online/list", data).pipe(
      tap(() => this._loading$.next(true)
      ),
      delay(200),
      map((res: any) => {
        this._loading$.next(false)
        return res;
      })
      )
    }

  getCreditOnlineById(id: number) {
    return this.http.get(this.url + `/credit-online/${id}`)
  }

  getWorkDuration(){
    return this.http.get(this.url + '/finance-work-duration/list')
  }
  upDateCreditOnline(data: any, id: number) {
    return this.http.put(this.url + `/credit-online/${id}`, data)
    .pipe(
      tap(() => this._loading$.next(true)),
      delay(200),
      map((res) => {
        this._loading$.next(false);
        return res;
      })
    )
  }

  updateSellerEmployeeApprove(data : any,id:number){
    let res = this.http.put(this.url +`/credit-online/employee-approve/${id}`,data);
    return res;
  }

  updateSellerfinanceApprove(data : any,id:number){
    let res = this.http.put(this.url +`/credit-online/sellerfinance-approve/${id}`,data);
    return res;
  }
  addCreditOnline(data: any) {
    return this.http.post(this.url + `/credit-online`, data)
    .pipe(
      tap(() => this._loading$.next(true)),
      delay(200),
      map((res) => {
        this._loading$.next(false);
        return res;
      })
    )
  }

  docGroup1() {
    return this.http.get(this.url + '/credit-online/doc1/group1')
  }
  docGroup2() {
    return this.http.get(this.url + '/credit-online/doc2/group2')
  }
  docGroup3() {
    return this.http.get(this.url + '/credit-online/doc3/group3')
  }
  planAuctionByName() {
    return this.http.get(this.url + '/plan_auction/name')
  }

  financeStatus() {
    return this.http.get(this.url + '/finance-status')
  }
  get loading$() { return this._loading$.asObservable(); }

}

