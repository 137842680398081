// ข้อมูลแผนการประมูล

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import {delay, map , tap } from "rxjs/operators";
import { environment } from "../../../environments/environment";

@Injectable()
export class PlanBiddingService {
  private url = environment.apiUrl;
  private _loading$ = new BehaviorSubject<boolean>(true);
  private _loading2$ = new BehaviorSubject<boolean>(false);
  constructor(private http: HttpClient) {
  }
   // plan bidding 
   planBiddingList(data) {
    return this.http.post(this.url + '/plan_auction/list', data).pipe(
      tap(() => this._loading$.next(true)
      ),
      delay(200),
      map((res: any) => {
        this._loading$.next(false)
        return res;
      })
      )
  }
  
  updateplanBiddingList(data) {
    return this.http.put(this.url + `/plan_auction`, data)

  }
  addPlanBidding(data) {
    return this.http.post(this.url + `/plan_auction`, data)
  }

  planBiddingListById(id: number) {
    return this.http.get(this.url + `/plan_acution/${id}`)
  }

  vehicleInplanBiddingList(data) {
    return this.http.post(this.url + `/plan_acution/vehicle`,data).pipe(
      tap(() => this._loading2$.next(true)
      ),
      delay(200),
      map((res: any) => {
        this._loading2$.next(false)
        return res;
      })
      )
  }
  planVehicleById(data: any) {
    return this.http.post(this.url + `/plan_acution/vehicle_byId`,data).pipe(
      tap(() => this._loading$.next(true)
      ),
      delay(200),
      map((res: any) => {
        this._loading$.next(false)
        return res;
      })
      )
  }
  updateVehicleInPlan(data: any) {
    return this.http.put(this.url + '/plan_acution/vehicle', data)
  }

  // get branch()
  getBranch() {
    return this.http.get(this.url + '/branch');
  }
  getYearReg() {
    return this.http.get(this.url + '/year');
  }
  getYear() {
    return this.http.get(this.url + '/year/bu');
  }
  getBrand() {
    return this.http.get(this.url + '/brand');
  }
  getModel(data) {
    return this.http.post(this.url + '/model', data);
  }
  getSubmodel(data) {
    return this.http.post(this.url + '/submodel', data);
  }
  getColor() {
    return this.http.get(this.url + '/color');
  }
  get loading$() { return this._loading$.asObservable(); }
  get loading2$() { return this._loading2$.asObservable(); }

}
