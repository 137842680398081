import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';

import { AuthGuardPages } from './_shards/auth.guard';

const routes: Routes = [
  {
    path: 'pages',
    canActivate: [AuthGuardPages],
    loadChildren: () => import('./pages/pages.module')
      .then(m => m.PagesModule),
  },
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module')
    .then(m => m.AuthModule),
  },
  {
    path: 'myaccount',
    loadChildren : ()=> import('./myaccount/myaccount.module')
    .then(m => m.MyaccountModule),
  },


  { path: '', redirectTo: 'auth', pathMatch: 'full' },
  { path: '**', redirectTo: 'auth' },
];

const config: ExtraOptions = {
    useHash: true,
    relativeLinkResolution: 'legacy'
}

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
